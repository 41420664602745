<template>
    <div class="register">
        <div class="ros-logo">
            <img src="@/assets/main_logo.png" alt="">
        </div>
        <div class="notice">
            <p style="font-size:24px;">ברוכים הבאים</p>
            <p style="font-size:18px; color:var(--light)">אנא מלאו את הפרטים הבאים</p>
        </div>

        <el-input prefix-icon="el-icon-user-solid" placeholder="שם פרטי" v-model="new_user.name" type="text" @change="validate_form('name')"></el-input>
        <p class="error" v-if="new_user_errors.name"><i class="material-icons">error</i> {{new_user_errors.name}}</p>

        <el-input prefix-icon="el-icon-phone" placeholder="מספר פלאפון" v-model="new_user.phone" type="text" @change="validate_form('phone')"></el-input>
        <p class="error" v-if="new_user_errors.phone"><i class="material-icons">error</i> {{new_user_errors.phone}}</p>

        <el-input prefix-icon="el-icon-message" placeholder="כתובת אימייל" v-model="new_user.email" type="email" @change="validate_form('email')"></el-input>
        <p class="error" v-if="new_user_errors.email"><i class="material-icons">error</i> {{new_user_errors.email}}</p>

        <el-input prefix-icon="el-icon-lock" placeholder="סיסמה" v-model="new_user.password" type="password" @change="validate_form('password')"></el-input>
        <p class="error" v-if="new_user_errors.password"><i class="material-icons">error</i> {{new_user_errors.password}}</p>

        <el-input prefix-icon="el-icon-lock" placeholder="סיסמה שנית" v-model="new_user.password_2" type="password" @change="validate_form('password_2')"></el-input>
        <p class="error" v-if="new_user_errors.password_2"><i class="material-icons">error</i> {{new_user_errors.password_2}}</p>

        <el-button type="success" style="width:100%; height:50px;" @click="finish_form">הירשם</el-button>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectAuth, projectFunctions } from '../../firebase/config'
import Swal from 'sweetalert2';
import {slide_pop_successs} from '../../Methods/Msgs';
import store from '../../store';
import router from '../../router';
export default {
setup(){
    const new_user = ref({
        name: '',
        phone: '',
        email: '',
        password: '',
        password_2: ''
    })

    const new_user_errors = ref({
        name: '',
        phone: '',
        email: '',
        password: '',
        password_2: ''
    })

    const validate_form = (field) => {
        switch (field) {
            case 'name':
                if(new_user.value.name.length == 0){
                    new_user_errors.value.name = "אנא מלא שם פרטי"
                }else{
                    new_user_errors.value.name = '';
                }
                break;
            case 'phone':
                if(new_user.value.phone.length != 10){
                    new_user_errors.value.phone = "אנא מלא מספר פלאפון תקני"
                }else{
                    new_user_errors.value.phone = '';
                }
                break;
            case 'password':
                if(new_user.value.password.length < 6){
                    new_user_errors.value.password = "הסיסמה חייבת להכיל לפחות 6 תווים"
                }else{
                    new_user_errors.value.password = '';
                }
                break;
            case 'email':
                if(!new_user.value.email.includes('@')){
                    new_user_errors.value.email = "אנא מלא כתובת אימייל תקינה"
                }else{
                    if(new_user.value.email.length < 8){
                        new_user_errors.value.email = "אנא מלא כתובת אימייל תקינה"
                    }else{
                        new_user_errors.value.email = '';
                    }
                }
                break;
            case 'password_2':
                if(new_user.value.password != new_user.value.password_2){
                    new_user_errors.value.password_2 = "הסיסמאות חייבות להיות זהות"
                }else{
                    new_user_errors.value.password_2 = '';
                }
                break;
            default:
                break;
        }
    }
    const finish_form = () => {
        const keys = Object.keys(new_user_errors.value);
        for(let i = 0; i< keys.length; i++){
            validate_form(keys[i])
        }
        const check = Object.keys(new_user_errors.value);
        for(let i = 0; i< check.length; i++){
            if(new_user_errors.value[check[i]].length != 0){
                return
            }
        }
        register();
    }
    const register = async () => {
        projectAuth.createUserWithEmailAndPassword(new_user.value.email, new_user.value.password)
        .then(async (userCredential) => {
            // Signed in 
            var user = userCredential.user;
            store.state.loader = true;
            await projectFunctions.httpsCallable('create_profile')({uid: user.uid, name: new_user.value.name, phone:new_user.value.phone, 
                email: new_user.value.email});
            store.state.loader = false;
            slide_pop_successs('המשתמש נוצר בהצלחה, הינך מחובר למערכת.')
            window.location.reload();
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            Swal.fire({
                icon: 'error',
                title: 'Error' + ' ' + errorCode,
                text: errorMessage
            })
        });
    }
    return{
        new_user, new_user_errors, validate_form, finish_form
    }
}
}
</script>

<style scoped>
.register{
    width: 100%;
    max-width: 375px;
    height: 100%;
    margin: auto;
    padding: 5px;
    overflow-y: auto;
}
.ros-logo{
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
}
.ros-logo img{
    max-width: 90%;
    max-height: 90%;
}
.notice{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--yellow);
    margin-bottom: 10px;
    flex-shrink: 0;
}
::v-deep(.el-input__inner){
    text-align: center;
    font-size: 18px;
    width: 100%;
    margin-top: 5px;
}
.error{
    width: 100%;
    text-align: start;
    font-size: 14px;
    color: var(--danger);
    padding: 2px 0 5px 0;
    display: flex;
    align-items: center;
}
</style>